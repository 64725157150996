<template>
  <div class="vr">
    <iframe
      class="pre-bg-img2"
      width="100vw"
      height="100vh"
      :src="iframeSrc"
    ></iframe>
    <MyNav></MyNav>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
  },
  data() {
    return {
      iframeSrc: "",
    };
  },
  computed: {
    ...mapState({
      farmInfo: (state) => state.user.farmInfo,
    }),
  },
  created() {
    this.iframeSrc = this.farmInfo.vr_page;
  },
};
</script>

<style lang="less" scoped>
.vr {
  width: 100%;
  min-height: 100vh;
  padding-top: 62px;
  background-color: #071a2c;
  padding: 72px 0 0;
  color: #fff;
  overflow-x: hidden;
  .pre-bg-img2 {
    width: 100vw;
    height: 100vh;
  }
}
</style>
